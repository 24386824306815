<template>
  <b-container>
    <h3>Statistica Ethicae Spinozae</h3>
  </b-container>
</template>

<script>
export default {
  name: "Statistica"
}
</script>

<style scoped>

</style>