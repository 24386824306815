<template>
<b-container>
  <h3>Lexicon Ethicae Spinozae</h3>

</b-container>
</template>

<script>
export default {
  name: "Lexicon"
}
</script>

<style scoped>

</style>